import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "@/core/services/store";
import VueMobileDetection from "vue-mobile-detection";
import VueLoading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';
import * as VueGoogleMaps from "vue2-google-maps";

Vue.config.productionTip = false;
Vue.use(VueMobileDetection);
Vue.use(VueLoading);
Vue.use(VueToast, {
  position: 'top-right',
  duration: 5000
});
Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyCsRO7ndzhwHDGxEK4YldhQ5nxAAnJRP28",
    libraries: ["places"],
  },
});

// Global 3rd party plugins
import "popper.js";
import "tooltip.js";
import PerfectScrollbar from "perfect-scrollbar";
window.PerfectScrollbar = PerfectScrollbar;
import ClipboardJS from "clipboard";
window.ClipboardJS = ClipboardJS;

// Vue 3rd party plugins
import vuetify from "@/core/plugins/vuetify";
import "@/core/plugins/portal-vue";
import "@/core/plugins/bootstrap-vue";
import "@/core/plugins/perfect-scrollbar";
import "@/core/plugins/highlight-js";
import "@/core/plugins/inline-svg";
import "@/core/plugins/apexcharts";
import "@/core/plugins/metronic";
import "@mdi/font/css/materialdesignicons.css";

const isMobile = {
  Android: function() {
      return navigator.userAgent.match(/Android/i);
  },
  BlackBerry: function() {
      return navigator.userAgent.match(/BlackBerry/i);
  },
  iOS: function() {
      return navigator.userAgent.match(/iPhone|iPad|iPod/i);
  },
  Opera: function() {
      return navigator.userAgent.match(/Opera Mini/i);
  },
  Windows: function() {
      return navigator.userAgent.match(/IEMobile/i) || navigator.userAgent.match(/WPDesktop/i);
  },
  any: function() {
      return (isMobile.Android() || isMobile.BlackBerry() || isMobile.iOS() || isMobile.Opera() || isMobile.Windows());
  }
};

Vue.mixin({
  data: function() {
    return {
      RESULT_SUCCESS: 1000,
      ORDER_TYPE_BOX: 'Box'
    }
  }
});

router.beforeEach((to, from, next) => {
  next();

  // Scroll page to top on every route change
  setTimeout(() => {
    var offsetPosition = 0;
    const divId = to.hash ? to.hash.substring(1) : 'container';
    const element = document.getElementById(divId);
    if (element) {
      const elementPosition = element.getBoundingClientRect().top;
      offsetPosition = isMobile.any() ? elementPosition - 50 : elementPosition - 80;
    }
    window.scrollBy({
      top: offsetPosition,
      behavior: "smooth"
    });
  }, 100);
});

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount("#app");

import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getFunctions } from 'firebase/functions';

// Initialize Firebase
const firebaseConfig = {
  apiKey: "AIzaSyALteKh55h7rpSit2ttamZ_SrMOUJG-fr4",
  authDomain: "movx-web.firebaseapp.com",
  projectId: "movx-web",
  storageBucket: "movx-web.appspot.com",
  messagingSenderId: "972238964474",
  appId: "1:972238964474:web:e6c61cf70370c15e26eb43",
  measurementId: "G-K6JBKZ96KN"
};
// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
export const firestore = getFirestore(firebaseApp);
export const functions = getFunctions(firebaseApp);
export const firebaseAuth = getAuth(firebaseApp);