import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

export default new Router({
  mode: 'history',
  routes: [
    {
      path: "/",
      redirect: "/",
      component: () => import("@/view/layout/Layout.vue"),
      children: [
        {
          path: "/",
          name: "Main",
          component: () => import("@/view/pages/Main.vue")
        },
        {
          path: "/booking",
          name: "Booking",
          component: () => import("@/view/pages/Booking.vue")
        },
        {
          path: "/confirm_payment",
          name: "ConfirmPayment",
          component: () => import("@/view/pages/ConfirmPayment.vue")
        },
        {
          path: "/orders",
          name: "OrderList",
          component: () => import("@/view/pages/order/OrderList.vue")
        },
        {
          path: "/order/:id",
          name: "OrderDetail",
          component: () => import("@/view/pages/order/OrderDetail.vue")
        },
        {
          path: "/boxes",
          name: "BoxList",
          component: () => import("@/view/pages/boxes/BoxList.vue")
        },
        {
          path: "/box/:id",
          name: "BoxDetail",
          component: () => import("@/view/pages/boxes/BoxDetail.vue")
        },
        {
          path: "/profile",
          name: "Profile",
          component: () => import("@/view/pages/Profile.vue")
        },
        {
          path: "/auth",
          name: "Auth",
          component: () => import("@/view/pages/Auth.vue")
        },
        {
          path: "/register",
          name: "Register",
          component: () => import("@/view/pages/Register.vue")
        },
        {
          path: "/terms",
          name: "Terms",
          component: () => import("@/view/pages/Terms.vue")
        }
      ]
    },
    {
      path: "*",
      redirect: "/404"
    },
    {
      // the 404 route, when none of the above matches
      path: "/404",
      name: "404",
      component: () => import("@/view/pages/Page404.vue")
    }
  ]
});
